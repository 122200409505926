* {
  font-family: 'Jost', sans-serif;
}
.page-loader {
  width: 100%;
  height: 100vh;
  background: #272727;
  z-index: 1000;
  position: fixed;
}
.page-loader .txt {
  color: #f56004;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}
.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}



@media screen and (max-width: 499px) {
  .discordBig{
    display: none;
  }
  
  .os{
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  .discord3 {
    display: none;
  }
 
  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
    transition: transform .2s;
    /* Animation */
  }

  .discordFooter {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
    transition: transform .2s;
    /* Animation */
  }

  
  .discordFooter2 {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    width: 15%;
    /* Animation */
  }

  .discord2 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }
  .connect3 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 25px;
    font-weight: 700;
    display: none;

  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;
  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;

  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    font-size: 20px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 220px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 60px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    padding: 8px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 50px;
    width: 170px;
    justify-content: flex-end;
    border: none;

  }


  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 80%;
    font-size: 15px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 120%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 15px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 97%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: none;
  }

  .websiteBannerPicMobile2 {
    width: 100%;
   
  }

  .websiteBannerPicMobile {
    width: 100%;
    background: url('./assets/mainBannerMobile.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: none;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
    display: none;
  }

  .storyBannerPicMobile {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }


  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
    display: none;
  }

  .websiteBannerPicMobile {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;

  }

  .websiteBannerPicDivMobile {
    display: inline-block;
    margin-bottom: 10.5%;
    display: none;
  }

  .websiteBannerPicDivMobile2 {
    display: inline-block;
    margin-bottom: 10.5%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 330px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 20px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;
    margin-left: 5%;
  }

  .contentRM {
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    margin-left: 5%;
  }

  .mainContentRm {
    width: 90%;
    padding-left: 5%;
  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 85px;
    height: 47px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 150px;
    height: 30px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 400px;
    width: 270px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 4%;
    top: 15%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}
@media screen and (min-width: 500px) and (max-width: 767px) {
  .discordBig{
    display: none;
  }

  .os{
    width: 35px;
    height: 35px;
    cursor: pointer;

  }
  .discordFooter2 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }
  .discordFooter {
    display: none;
  }

    .websiteBannerPicMobile2{
    display: none;
  }
  
  .discord3 {
    display: none;
  }
 
  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    display: none;
    transition: transform .2s;
    /* Animation */
  }

  .discord2 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }
  .connect3 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 25px;
    font-weight: 700;
    display: none;

  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;
  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 300px;

  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 70%;
    width: 70%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 23px;
    border-radius: 50px;
    width: 210px;
    justify-content: flex-end;

  }
  

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 80%;
    font-size: 15px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 120%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 18px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 95%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: none;
  }

  .websiteBannerPicMobile {
    width: 100%;
    background: url('./assets/mainBannerMobile.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
    display: none;
  }

  .storyBannerPicMobile {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }


  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
    display: none;
  }

  .websiteBannerPicMobile {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;

  }

  .websiteBannerPicDivMobile {
    display: inline-block;
    margin-bottom: 10.5%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 200px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 20px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;
    margin-left: 5%;
  }

  .contentRM {
    font-size: 15px;
    text-align: left;
    font-weight: bold;
    margin-left: 5%;
  }

  .mainContentRm {
    width: 90%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 85px;
    height: 47px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 150px;
    height: 30px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 445px;
    width: 380px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 10%;
    top: 27%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .discordBig{
    display: none;
  }

  .os{
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 0, 0);
    cursor: pointer;

  }

  .discordFooter2 {
    display: none;
  }

  .websiteBannerPicDivMobile2{
    display: none;
  }
  .websiteBannerPicMobile2{
    display: none;
  }
  
  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .storyBannerPicMobile{
    display: none;
  }
  
  .discord3 {
    display: none;
  }
 
  .discord2 {
    display: none;
  }
  
  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }
  .discordFooter {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }
  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 25px;
    font-weight: 700;
    display: none;

  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 300px;

  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    border-radius: 50px;
    width: 210px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 18px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 70%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 22px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 200px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 25px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 18px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 85%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 100px;
    height: 55px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 200px;
    height: 41px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 440px;
    width: 380px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 25%;
    top: 18%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .discordBig{
    display: none;
  }
  
  .os{
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 0, 0);
    cursor: pointer;

  }
  .discordFooter {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }
  .discordFooter2 {
    display: none;
  }
  .websiteBannerPicDivMobile2{
    display: none;
  }

  .websiteBannerPicMobile2{
    display: none;
  }
  
  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .storyBannerPicMobile{
    display: none;
  }
  .discord3 {
    display: none;
  }
  
  .discord2 {
    display: none;
  }
  
  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 25px;
    font-weight: 700;
  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 300px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 210px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 22px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 95%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 25px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 200px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 30px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 70%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 100px;
    height: 55px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 240px;
    height: 49px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 430px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 30%;
    top: 20%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .discordBig{
    display: none;
  }
  
  .os{
    width: 40px;
    height: 40px;
    background-color: rgba(255, 0, 0, 0);
    cursor: pointer;

  }

  .osDiv{
    background-color: rgba(255, 0, 0, 0);

  }
  .discordFooter {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }
  .discordFooter2 {
    display: none;
  }
  .websiteBannerPicMobile2{
    display: none;
  }
  
  .websiteBannerPicDivMobile2{
    display: none;
  }
  
  .storyBannerPicMobile{
    display: none;
  }

  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .discord2 {
    display: none;
  }
  .discord3 {
    display: none;
  }
  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;

  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
  /*color: #ffffffa2;
}*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 25px;
    font-weight: 700;
  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
  text-align: center;
  margin-top: 5px;
  font-size: 45px;
  color: white;
  font-family: 'Dongle', sans-serif;
}
.name2{
  color: white;
  text-align: center;
  margin-top: 2px;
  font-size: 18px;
  color: #6e5a88;
  
}*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 30px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 300px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;

  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 210px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 25px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 125%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 25px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 20px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');    
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 30px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 200px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 30px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 20px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 70%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 100px;
    height: 55px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 240px;
    height: 49px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 430px;
    width: 400px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 35%;
    top: 20%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {
  .discordBig{
    display: none;
  }
  
  .os{
    width: 50px;
    height: 50px;
    cursor: pointer;

  }
  .discordFooter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    width: 3%;
    /* Animation */
  }
  .discordFooter2 {
    display: none;
  }
  .websiteBannerPicMobile2{
    display: none;
  }
  .websiteBannerPicDivMobile2{
    display: none;
  }

  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .storyBannerPicMobile{
    display: none;
  }
  
  .discord2 {
    display: none;
  }

  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;
  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }

  .discord3 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 35px;
    font-weight: 700;
  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 400px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);

  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 50px;
    width: 300px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 38px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 110%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 35px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 30px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 100px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
    
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 300px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 40px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 70%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 142px;
    height: 78px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 296px;
    height: 60px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 500px;
    width: 600px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 40%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 35%;
    top: 20%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .discordBig{
    display: none;
  }
  
  .os{
    width: 50px;
    height: 50px;
    cursor: pointer;

  }
  .discordFooter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    width: 3%;
    /* Animation */
  }
  .discordFooter2 {
    display: none;
  }
  .websiteBannerPicMobile2{
    display: none;
  }
  .websiteBannerPicDivMobile2{
    display: none;
  }

  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .storyBannerPicMobile{
    display: none;
  }
  
  .discord2 {
    display: none;
  }

  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;
  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }

  .discord3 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discord:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 35px;
    font-weight: 700;
  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 23px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 35px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 55px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 40px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 400px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 75px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 50px;
    width: 300px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 38px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 110%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 35px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 30px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 100px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100vw;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
    width: 100%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    border-color: #392a4d;
    border-width: 6px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 5px;
    height: 300px;
    margin-left: 12.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 40px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 70%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 142px;
    height: 78px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 296px;
    height: 60px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 500px;
    width: 600px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 40%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.514);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 35%;
    top: 20%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 40px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 3840px) {
  .os{
    width: 80px;
    height: 80px;
    cursor: pointer;

  }
  .discordFooter {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }
  .discordFooter2 {
    display: none;
  }
  .websiteBannerPicMobile2{
    display: none;
  }
  .websiteBannerPicDivMobile2{
    display: none;
  }

  .websiteBannerPicMobile{
    display: none;
  }

  .websiteBannerPicDivMobile{
    display: none;
  }
  .storyBannerPicMobile{
    display: none;
  }
  
  .discord2 {
    display: none;
  }

  a {
    color: #5abbe7;
    text-decoration: none;
  }

  a:hover {
    color: #5abbe7;
    text-decoration: none;
  }

  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 20px;
    color: #ff0800;
    text-align: center;
  }

  .successfully {
    margin-top: 1%;
    font-size: 20px;
    color: #000000;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 1%;
  }

  .loadingText {
    font-size: 50px;
    text-align: center;
    color: rgb(255, 255, 255);
  }

  .loadTextSub {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 40px;

  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;

  }

  .wPMobile {
    display: none;
  }

  .introductionMobile {
    display: none
  }

  .hl {
    color: #6ce34d;
  }

  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: auto;
    padding-top: auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .discord {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }

  .discord3 {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    display: none;
    /* Animation */
  }

  .discordBig {
    padding-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: transform .2s;
    /* Animation */
  }

  .discordBig:hover {
    transform: scale(1.1);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #f56004;
  }



  .note {
    color: rgb(255, 255, 255);
  }

  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;

  }

  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/

  }

  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;

  }





  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


  }

  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    padding-top: 5px;
    margin-left: 15%;
  }

  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 5%;
  }

  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    color: rgb(0, 0, 0);
    padding-top: 20px;
    font-size: 60px;
    font-weight: 700;
  }

  .connect div:hover {
    color: #f56004;
    cursor: pointer;

  }




  /*...................... Story .................... */
  .boxWrap2 {
    display: inline-block;

  }

  .storyPic {

    width: 400px;
    height: 400px;
    margin-left: 20%;
    margin-top: 10%;
  }

  .storyPicDiv {}


  /*...................... Story .................... */

  .roadmapHeader {

    text-align: center;
    font-size: 75px;
    color: white;
    margin-bottom: 3%;



  }

  .roadmapbg1 {
    padding-top: 3%;
    background-size: cover;
    background-color: #2d3636;
    height: fit-content;

  }



  .teamHeader {

    background-size: cover;
    height: 100vh;
    overflow-x: hidden;


  }


  .group {}

  .teamMainSection {
    display: flex;
    flex-flow: column nowrap;

  }

  .section {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .gt {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin-bottom: 15px;
    box-shadow: 0 0 10px rgb(107, 240, 102);
    transition: transform .2s;
  }

  .gt:hover {
    transform: scale(1.1);
  }

  .maindivT {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }




  /*.name{
    text-align: center;
    margin-top: 5px;
    font-size: 45px;
    color: white;
    font-family: 'Dongle', sans-serif;
  }
  .name2{
    color: white;
    text-align: center;
    margin-top: 2px;
    font-size: 18px;
    color: #6e5a88;
    
  }*/


  .Banner {
    width: 300px;
    height: 294px;
    animation: zoominoutsinglefeatured 5s infinite;

  }

  .name1 {

    text-align: center;
    font-size: 40px;
    color: rgb(255, 255, 255);
    margin-bottom: 20px;
  }

  .name2 {

    text-align: center;
    font-size: 30px;
    color: rgb(37, 37, 37);
    margin-bottom: 15px;
  }

  .footer {
    background-color: #f8f4dc;
    padding: 3%;
    position: static;

  }

  .copyright {
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 10px;
  }



  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }

  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 60px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    width: 120px;
    box-shadow: black 0px 5px;
    border-radius: 10px;
    border: none;
  }

  .btnfos-0-2:hover {

    color: #f56004;
    cursor: pointer;


  }

  .btnfos-0-3 {
    margin-top: 5%;
    font-size: 70px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    box-shadow: black 0px 5px;
    border-radius: 50px;
    width: 700px;
  }

  .btnfos-0-3:hover {

    color: #f56004;
    cursor: pointer;

  }

  .nftamount {

    color: #f56004;
    font-size: 120px;
  }

  .walletConnect {

    display: flex;
    justify-content: center;
  }

  .connectButton {

    font-size: 45px;
  }

  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;

  }

  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }

  .errorMessage {
    text-align: center;
    padding-top: 20px;
    font-size: 50px;
    color: #f77d79;

  }

  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(4, 121, 4);
    font-size: 50px;

  }

  .name {
    height: 80%;
    width: 80%;
    display: block;
    object-fit: contain;
    padding-top: 10px;
  }

  .logoName {

    font-size: 18px;
    color: white;
    padding-top: 20px;
  }

  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1000;
    margin-bottom: -11%;
    z-index: 10;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .allWrap {

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
    overflow: hidden;
  }




  .intro {
    color: white;
    font-size: 45px;
    text-align: left;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: justify;


  }

  .flash {
    font-size: 72px;
    background: -webkit-linear-gradient(#6ce34d, #6ee650, #fbb03b, rgb(238, 182, 93), #f5d169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Bangers', cursive;
    padding-left: 10%;

  }

  .introduction {
    padding-left: 7%;
    padding-top: 7%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 8%;
  }

  .create:hover {
    background-color: white;
    color: #f38b1d;
  }

  .create a {
    text-decoration: none;
    color: #f38b1d;
  }

  .create a:hover {
    color: #f38b1d;
  }

  .wallet2 {
    background-color: rgb(0, 0, 0);
    border-style: solid;
    color: rgb(255, 255, 255);
    padding: 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 50px;
    border-radius: 50px;
    width: 500px;
    justify-content: flex-end;

  }

  .wallet2:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {

    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  .wallet3 {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;

    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #f5d169;


  }

  .wallet3:hover {
    color: rgb(255, 187, 0);
    border: 2px solid #91bb2e;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 24px #f5d169;
  }


  .in2 {
    width: 50%;
  }

  .logo {
    width: 50%;
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    z-index: 100;
    padding: 5px;
  }

  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }

    10%,
    87.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    20%,
    92.5% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }

  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }

    10% {
      opacity: 0;
    }

    20% {
      opacity: 1;
    }

    25%,
    95% {
      transform: translateY(0);
    }

    90% {
      opacity: 1;
    }

    95% {
      opacity: 0;
    }

    100% {
      transform: translateY(250px);
      opacity: 0;
    }

  }






  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes Slide_Left_Right {

    0%,
    100% {
      transform: translateX(100px);
    }

    12.5%,
    75% {
      transform: translateX(0);
    }

    25% {
      transform: translateX(20px);
    }

  }

  @keyframes Opacity {

    0%,
    100% {
      opacity: 0;
    }

    25%,
    75% {
      opacity: 1;
    }


  }

  .storyDetails {
    color: white;
    padding-top: 10px;

  }

  .storyH {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;

  }

  .storyRight {
    width: 75%;
  }

  .storyCon {
    color: rgba(0, 0, 0, 0.877);
    width: 65%;
    font-size: 38px;
    font-weight: bold;
    margin-left: 5%;
    position: absolute;
    left: 0px;
    top: 110%;
    z-index: 1;
  }

  .storyCon2 {
    color: rgba(255, 255, 255, 0.877);
    width: 85%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon5 {
    color: rgba(255, 255, 255, 0.877);
    width: 70%;
    font-size: 14px;
    line-height: 2;

  }

  .storyCon3 {
    color: rgba(255, 255, 255, 0.877);
    width: 100%;
    font-size: 14px;
    line-height: 2;

  }


  /*.............. FAQ ..............*/


  summary {
    font-size: 60px;
    font-weight: 600;
    background-color: rgba(29, 29, 29, 0);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    border-style: solid;
    text-align: left;
    cursor: pointer;
    position: relative;
    font-weight: bold;
    border-left: #00000000;
    border-right: #090a0f00;
    border-top: #00000000;
  }


  details>summary::after {
    position: absolute;
    content: "+";
    right: 20px;

  }

  details[open]>summary::after {
    position: absolute;
    content: "-";
    right: 20px;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    height: 600px;
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;


  }

  .faq__content {

    padding-bottom: 20px;
    text-align: left;

  }

  .fContent {
    font-weight: bold;
    font-size: 55px;
    color: rgb(0, 0, 0);

  }

  .faqTitle {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    color: #000000;

  }

  .faqbg {

    background-size: cover;
    width: 100%;
    text-align: center;
    padding: 2px;
    margin: 0 auto;
    background: url('./assets/faqBg.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-bottom: 100px;
    padding-top: 30px;
  }

  .utilityH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .rH {
    color: white;
    text-align: center;
    font-size: 40px;
    font-weight: bold;
    margin-top: 10%;
  }

  .com {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .com2 {
    color: white;
    font-size: 15px;
    text-align: justify;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: 1%;
  }

  .mp {
    flex-flow: row;
    justify-content: space-between;
    width: 25%;
    display: flex;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;


  }

  .btn {
    transition: transform .2s;
    border-radius: 10px;
    padding: 15px;
    color: white;
    background-image: linear-gradient(to right top, #53aa3c, #63ae35, #73b12e, #82b426, #91b71d, #9db71f, #a8b622, #b2b626, #b9b333, #bfaf3f, #c4ad4a, #c7aa55);
  }

  .btn:hover {
    transform: scale(1.05);
    color: white;

  }

  .buy {
    padding: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-style: unset;
    border-radius: 7px;
    background-color: #906cc4;
    color: white;
    box-shadow: #72559b 0px 6px;
    font-size: 20px;

  }

  .buy:hover {
    background-color: #7f5caf;
    color: white;
    box-shadow: #5a447a 0px 6px;
  }

  .buy:active {
    background-color: #624886;
    box-shadow: 0 5px #392a4d;
    transform: translateY(4px);
  }

  .twoBtn {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 10%;
  }

  .boxWrap3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 5%;

  }



  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-top: 15%;
    padding-left: 10%;
    padding-right: 10%;

  }

  .boxWrap4 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .wP {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-top: 10%;

  }

  .uR {}

  .cR {}

  .utilPic {
    width: 600px;
    height: 400px;

  }

  .utilPicDiv {
    margin-top: 7%;
  }

  .conPic {
    width: 530px;
    height: 336px;
  }

  .conPicDiv {}

  .utilH {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 5%;

  }

  .utilH5 {
    font-size: 15px;
    color: #5abbe7;
    font-weight: bold;
    letter-spacing: 3px;
    margin-bottom: 2%;

  }

  .utilH2 {
    color: white;
    margin-bottom: 2%;
    font-size: 35px;
    font-weight: bold;
    width: 95%;
  }

  .line3 {
    width: 34%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line {
    width: 12.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .line2 {
    width: 19%;
    height: 2px;
    background-color: #5abbe7;
  }


  .line4 {
    width: 14.5%;
    height: 2px;
    background-color: #5abbe7;
  }

  .teamPic {
    width: 200px;
    height: 302px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 10%;
  }

  .teamMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 20%;
  }

  .team1 {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.116);
    width: 24%;
    transition: transform .2s;
    /* Animation */
  }

  .team1:hover {
    transform: scale(1.1);
  }

  .nameT {
    color: white;
    text-align: center;
    margin-top: 3%;
    font-size: 20px;
  }

  .nameT2 {
    color: rgb(191, 141, 224);
    text-align: center;
  }

  .position {
    color: white;
    text-align: center;
    font-size: 12px;
    margin-top: 6%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center
  }

  .logoB {
    width: 200px;
    height: 39px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }

  .websiteBannerPic {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .roadmapBg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .storyBannerPic {
    width: 100vw;
    height: 100%;
    object-fit: contain;
    margin-top: -10%;
    margin-bottom: 6px;
  }

  .websiteBannerPicDiv {
    display: inline-block;
    margin-bottom: 10.5%;
    width: 100%;
  }

  /* Roadmap */

  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

  }


  .roadRm {
    width: 6%;
  }

  .roadmapMain {
    background-color: #f0cbd3;
    padding-top: 7%;
    padding-left: 5%;
  }

  .roadmapMainOne {}

  .circle {
    background-color: #ffffff;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    border-color: #392a4d;
    border-width: 10px;
    border-style: solid;

  }

  .lineRm {
    background-color: #392a4d;
    width: 10px;
    height: 600px;
    margin-left: 27.5px;
    margin-top: -2px;

  }

  .titleRM {
    font-size: 80px;
    font-weight: bolder;
    text-decoration: underline;
    padding-bottom: 15px;

  }

  .contentRM {
    font-size: 55px;
    text-align: left;
    font-weight: bold;
  }

  .mainContentRm {
    width: 70%;

  }

  .roadmapPicDiv {
    margin-bottom: 0.5%;
  }

  .faqNamePic {
    width: 300px;
    height: 165px;
    object-fit: contain;
  }

  .faqNamePicDiv {
    margin-bottom: 5%;
    margin-top: 1%;
  }

  .rmNamePic {
    width: 600px;
    height: 122px;
    object-fit: contain;
    justify-content: flex-start;
  }

  .rmNamePicDiv {
    padding-bottom: 5%;
  }

  .loginFormNote {
    height: 1000px;
    width: 1500px;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 40%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  /* RoadMap */
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.767);;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
  }

  .closeNote {
    position: absolute;
    right: 34%;
    top: 18%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 90px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .ts {
    text-align: center;
    font-size: 130px;
    font-weight: bold;
    margin-bottom: 5%;
    color: white;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }

    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }

  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }

    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }

    50% {
      transform: scale(1.05, 1.05);
    }

    100% {
      transform: scale(1, 1);
    }
  }
}